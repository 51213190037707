@import 'assets/styles/mixins.scss';

.topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 rem(20) 0 rem(50);
  min-height: 64px;
  height: 64px;
  background: $white;
  border-bottom: 1px solid $gray-border;
  color: $text;
}

.logo {
  width: 40px;
}
