@import 'assets/styles/mixins.scss';

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: $white;
  background-size: cover;
}
